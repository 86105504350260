import React from "react";

export const IconResetSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7011 0C9.90244 0 5.20106 4.70137 5.20106 10.5C5.20106 11.1392 5.26406 11.7626 5.37431 12.3703L1.72818 8.72287L0.798935 9.65213L6.04893 14.9021L6.05287 14.8982L6.51356 15.3602L6.97425 14.8982L6.97819 14.9021L12.2282 9.65213L11.2989 8.72287L6.8955 13.1276C6.64219 12.2797 6.51356 11.3991 6.51356 10.5C6.51356 5.43375 10.6348 1.3125 15.7011 1.3125C20.7673 1.3125 24.8886 5.43375 24.8886 10.5C24.8886 15.5662 20.7673 19.6875 15.7011 19.6875V21C21.4997 21 26.2011 16.2986 26.2011 10.5C26.2011 4.70137 21.4997 0 15.7011 0Z"
        fill="black"
      />
    </svg>
  );
};
