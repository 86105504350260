import React from "react";

export const BasketSVG = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9453 3.52233H11.0066V3.30834C11.0066 2.43091 10.6581 1.58943 10.0377 0.968991C9.41722 0.348556 8.57573 0 7.69831 0C6.82088 0 5.97939 0.348556 5.35895 0.968991C4.73852 1.58943 4.38996 2.43091 4.38996 3.30834V3.52233H0.451338C0.392063 3.52232 0.333366 3.53398 0.2786 3.55666C0.223834 3.57933 0.174073 3.61258 0.132159 3.65449C0.0902458 3.69641 0.0570013 3.74617 0.0343249 3.80093C0.0116485 3.8557 -1.53415e-05 3.9144 7.60095e-08 3.97367V19.0194C-3.43364e-05 19.0787 0.0116165 19.1374 0.0342861 19.1922C0.0569558 19.247 0.0901997 19.2967 0.132117 19.3387C0.174035 19.3806 0.223803 19.4138 0.278578 19.4365C0.333352 19.4592 0.392058 19.4708 0.451338 19.4708H14.9453C15.0046 19.4708 15.0633 19.4592 15.118 19.4365C15.1728 19.4138 15.2226 19.3806 15.2645 19.3387C15.3064 19.2967 15.3397 19.247 15.3623 19.1922C15.385 19.1374 15.3966 19.0787 15.3966 19.0194V3.97367C15.3966 3.9144 15.385 3.8557 15.3623 3.80093C15.3396 3.74617 15.3064 3.69641 15.2645 3.65449C15.2225 3.61258 15.1728 3.57933 15.118 3.55666C15.0632 3.53398 15.0045 3.52232 14.9453 3.52233ZM5.29264 3.30834C5.29264 2.67032 5.54609 2.05843 5.99724 1.60728C6.44839 1.15613 7.06028 0.902675 7.69831 0.902675C8.33633 0.902675 8.94822 1.15613 9.39937 1.60728C9.85052 2.05843 10.104 2.67032 10.104 3.30834V3.52233H5.29264V3.30834ZM14.4939 18.5681H0.902676V4.42501H14.4939V18.5681Z"
        fill="white"
      />
    </svg>
  );
};
