import { URL_POST_QUERY } from "./constants";

type BeginWithType = "Engagement Ring" | "Stacking Band"

type mouseEventsClickType = 
"Bands" |
"Engagement" |
"Band Above" |
"Band Below" |
"Start Over" |
"Cancel (start over)" |
"Reset (start over)" |
"Cancel (individual ring)" |
"Remove (individual ring)" |
"Review and Add To Bag" |
"Size Guide";

type optionSelectionSize =
3 |
3.25 |
3.5 |
3.75 |
4;

type optionSelectionType =
"First" |
"Second" |
"Third" |
"Size";


type typesSelections = {
	data: {
		name: string,
		id: string,
		color?: string
	}[],
}

export const beginWithEvent = async (type: BeginWithType) => {
	try {
		const objConfig = await {
			type: "BEGIN_WITH",
			data: {
				type
			}
		}

		await window.parent.postMessage(JSON.stringify(objConfig), URL_POST_QUERY);
	} catch (err) {
		console.error(err)
	}
}

export const mouseEventsClickEvent = async (type: mouseEventsClickType) => {
	try {
		const objConfig = await {
			type: "MOUSE_EVENTS",
			data: {
				click: type
			}
		}

		await window.parent.postMessage(JSON.stringify(objConfig), URL_POST_QUERY);
	} catch (err) {
		console.error(err)
	}
}

export const optionSelectionEvent = async ({data}: typesSelections) => {
	try {
		const objConfig = await {
			type: "OPTION_SELECTION",
			data
		}
		await window.parent.postMessage(JSON.stringify(objConfig), URL_POST_QUERY);
	} catch (err) {
		console.error(err)
	}
}