import React from "react";

export const ShareSVG = ({...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="21"
      viewBox="0 0 21 27"
      fill="none"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M18.5556 8.00539H13.7407V9.09704H18.5556C19.2593 9.09704 19.8519 9.67925 19.8519 10.3706V24.6348C19.8519 25.3261 19.2593 25.9084 18.5556 25.9084H2.40741C1.7037 25.9084 1.11111 25.3261 1.11111 24.6348V10.3706C1.11111 9.67925 1.7037 9.09704 2.40741 9.09704H6.85185V8.00539H2.40741C1.07407 8.00539 0 9.06065 0 10.3706V24.6348C0 25.9447 1.07407 27 2.40741 27H18.5926C19.9259 27 21 25.9447 21 24.6348V10.3706C20.963 9.06065 19.8889 8.00539 18.5556 8.00539ZM9.92593 2.07412V17.5027H11.037V2.07412L13.4815 4.47574L14.2593 3.71159L10.4815 0L6.66667 3.71159L7.44444 4.47574L9.92593 2.07412Z"
        fill="black"
      />
    </svg>
  );
};
