import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LongArrowStepper } from "../../assets/LongArrowStepper";
import { LongArrowStepperFinish } from "../../assets/LongArrowSteperFinish";
import { activeStepConfig } from "../../store/interface";
import { getActiveListElement, getCountActiveRing,getStepConfig } from "../../store/selectors/selectors";
import { LongStepperArrowMobile } from "../../assets/LongStepperArrowMobile";
import { ShortStepperArrowMobile } from "../../assets/ShortStepperArrowMobile";
import  FirstShortStepperMobile  from "../../assets/FirstShortStepperMobile";
import { FirstLongStepperMobile } from "../../assets/FirstLongStepperMobile";
import s from "./Stepper.module.scss";
import { mouseEventsClickEvent } from "../../utils/events";
import { showRemoveModal, setTempMobileActive, setActiveTypeEngagement, setEngagementChosen, setStepConfig, setActiveAuxiliaryButtons } from "../../store/actions/Settings";


interface stepInterf {
  name: string;
  description: string;
  mobileDescription:string;
}



export const steps: stepInterf[] = [
  {
    name: "1",
    description: "Start with an Engagement Ring or Stacking Band",
    mobileDescription: "Start with Ceremonial band",
  },
  {
    name: "2",
    description: "Choose Stacking bands",
    mobileDescription: "Select Engagement Ring or Nesting bands",
  },
  {
    name: "3",
    description: "Et Voila! Select Size and Add To Cart",
    mobileDescription: "Et Voila! Select Size and Add To Cart",
  },
];

 
export const Stepper = () => {

  let stepActive: number;
  let stepper = `${s.stepper}`;
  const dispatch = useDispatch();
  const stepConfig: activeStepConfig = useSelector(getStepConfig); 
  const countActiveRings = useSelector(getCountActiveRing);
  const activeList = useSelector(getActiveListElement);
  
  const onResetConfig = () => {
      dispatch(showRemoveModal(true));
      dispatch(setTempMobileActive(false));
      dispatch(setActiveTypeEngagement(false));
      dispatch(setEngagementChosen(false));
  };

  const changeStepConfig = (stepConfig: activeStepConfig) => {
    dispatch(setStepConfig(stepConfig));
    dispatch(setActiveAuxiliaryButtons(false));
  };

  const handleClick = (name: string) => {
    if(name === "1") {
      onResetConfig()
      mouseEventsClickEvent("Start Over")
    } else if(name === "2") {
      if(activeList.length > 0) {
        changeStepConfig('config')
      }
    } else if(name === "3") {
      changeStepConfig('basket')
      mouseEventsClickEvent("Review and Add To Bag")
    }
  }

  if (countActiveRings < 1) {
    stepActive = 1;
  }else {
    stepActive = 2;
  } 
  if(stepConfig === 'basket'){
    stepActive = 3;
    stepper += ` ${s.basket}`
  }
  if(stepConfig === 'config'){
    stepper += ` ${s.config}`;
  }
  return (
    <div className={stepper}>
      {steps.map((step: stepInterf) => {
        let isActiveStep = Number(step.name) === stepActive;

        let wrapStepClass = `${s.stepper_wrap}`;
        if (isActiveStep) wrapStepClass += ` ${s.active}`;

        return (
          <div className={wrapStepClass} onClick={() => handleClick(step.name)} style={{
            cursor: "pointer"
          }}>
            <div className={s.step}>
              <span>{step.name}</span>
              <div className={s.text}>{step.description}</div>
              <div className={s.textMobile}>{step.mobileDescription}</div>
            </div> 
            {["1", "2"].includes(step.name) ? (
               <>
                <LongArrowStepper className={s.LongArrowStepper}/>
               <div className={s.ShortArrowStepper}>
                  {
                   (step['name'] === "1" && isActiveStep) ?
                    (
                    <div className={s.ShortArrowStepper}>
                      <FirstLongStepperMobile/>          
                    </div> 
                    )
                    : (step['name'] === "1") ? 
                    (
                    <div className={s.LongArrowStepperMobile}>
                       <FirstShortStepperMobile/>
                    </div>
                    )
                    : isActiveStep ? 
                    (
                    <div className={s.LongArrowStepperMobile}>
                      <LongStepperArrowMobile/> 
                    </div>
                    )
                    : 
                    (
                    <div className={s.ShortArrowStepper}>
                      <ShortStepperArrowMobile />
                    </div>
                    )
                  }
               </div>
               </>
            ) : (
              <>
               <LongArrowStepperFinish className={s.LongArrowStepper}/>
               { isActiveStep ?
               <div className={s.LongArrowStepperMobileFinish}>
                  <LongStepperArrowMobile /> 
               </div>
               : 
               <div className={s.ShortArrowStepper}>
                  <ShortStepperArrowMobile />
               </div>}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
