import React from 'react';
import { useDispatch } from 'react-redux';
import { resetConfig, setActiveAuxiliaryButtons } from '../../store/actions/Settings';
import { mouseEventsClickEvent } from '../../utils/events';
import s from './RemoveModal.module.scss';


export const RemoveModal = ({ ...props }) => {
    const { closeFunc } = props;
    const dispatch = useDispatch();

    const onResetConfig = () => {
        closeFunc();
        dispatch(resetConfig());
        dispatch(setActiveAuxiliaryButtons(false));
    };
    return (
        <div className={`${s.modal}`}>
            <header>You can begin again, but your ring stack will not be saved.
                Are you sure you want to start over?</header>
            <main>
                <button className={`${s.btn} ${s.btn_black}`} onClick={() => {
                    closeFunc()
                    mouseEventsClickEvent("Cancel (start over)")
                }}>Cancel</button>
                <button className={`${s.btn} ${s.btn_white} `} onClick={() => onResetConfig()}>Start Over</button>
            </main>
        </div>
    )
}
