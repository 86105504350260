import React from "react";
import { Link } from "react-router-dom";
import { beginWithEvent } from "../../utils/events";
import s from "./StartPage.module.scss"; 

export const StartPage = () => {
  return (
    <div className={s.wrapPage}>
      <div className={s.startScrin}>
        <div className={s.img}>
          <img src="/img/pictureStarScrin.jpg" alt="" />
        </div>
        <div className={s.info}>
          <div className={s.title}>Build Your Ring Stack</div>
          <div className={s.description}>
            Explore countless ring and band combinations to find a sparkling look all your own—a distinctly modern way to mark your forever moments
          </div>
          <div className={s.box_button}>
            <Link to="/?type=engagement" className={s.btn} onClick={() => beginWithEvent("Engagement Ring")}> Start with an Engagement Ring </Link>
            <Link to="/?type=bands" className={s.btn} onClick={() => beginWithEvent("Stacking Band")}> Start with a Stacking Band </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
